var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"products"},[_c('div',{staticClass:"products-wrapper"},[_c('div',{staticClass:"products-content"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"page-title"},[_vm._v("Products")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":""},on:{"click":_vm.all}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-collapse-down")])],1)]}}])},[_c('span',[_vm._v("Show All")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":""},on:{"click":_vm.none}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-collapse-vertical")])],1)]}}])},[_c('span',[_vm._v("Hide All")])])],1),_c('div',{staticClass:"products-body mt-2"},[[_c('v-expansion-panels',{attrs:{"focusable":"","multiple":"","flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-server")]),_c('span',{staticClass:"subtitle-1"},[_vm._v("Product by Modules")])],1)]),_c('v-expansion-panel-content',[_c('v-card',{staticClass:"mt-1 col-md-12",attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"text-none",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.openProductDialog('module')}}},[_vm._v(" New Module Product ")])],1),_c('v-card-text',{staticClass:"subtitle-2"},[_c('v-row',{attrs:{"dense":""}},[(_vm.modules.length === 0)?_c('empty',{attrs:{"headline":"No main subscription plans yet."}}):_vm._l((_vm.modules),function(plan){return _c('v-sheet',{key:plan.id,staticClass:"col-md-6 col-sm-12 pa-1"},[_c('v-card',{staticClass:"border-1 border-color--grey",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"subtitle-1"},[_c('span',[_vm._v(_vm._s(plan.name))]),_c('span',{staticClass:"subtitle-2 ml-2"},[_vm._v(" | "+_vm._s(plan.plan_amount)+" "+_vm._s(plan.currency.toUpperCase())+" / "+_vm._s(_vm._f("ucwords")(plan.interval_type))+" ")]),_c('v-spacer'),_c('v-icon',{staticClass:"ml-1",on:{"click":function($event){return _vm.editPlan(plan)}}},[_vm._v(" mdi-circle-edit-outline ")]),_c('v-switch',{staticClass:"ml-2",attrs:{"false-value":"inactive","loading":plan.hover,"input-value":plan.status.toString(),"true-value":"active","label":("Set " + (plan.status.toString() === 'active'
                                      ? 'inactive'
                                      : 'active'))},on:{"change":function($event){return _vm.updatePlanStatus(plan)}}})],1),_c('v-card-text',[_c('p',[_c('span',{staticClass:"subtitle-2"},[_vm._v("Description :")]),_vm._v(" "+_vm._s(plan.description)+" "),_c('br'),_c('span',{staticClass:"subtitle-2"},[_vm._v("Live Mode :")]),_vm._v(" "+_vm._s(_vm._f("ucwords")(plan.live_mode.toString()))),_c('br'),_c('span',{staticClass:"subtitle-2"},[_vm._v("Product ID :")]),_vm._v(" "+_vm._s(plan.product_id)),_c('br'),_c('span',{staticClass:"subtitle-2"},[_vm._v("Price ID :")]),_vm._v(" "+_vm._s(plan.price_id)),_c('br'),_c('span',{staticClass:"subtitle-2"},[_vm._v("Source :")]),_vm._v(" "+_vm._s(_vm._f("ucwords")(plan.source))+" ")])])],1)],1)})],2)],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-server")]),_c('span',{staticClass:"subtitle-1"},[_vm._v("Product by Plugins")])],1)]),_c('v-expansion-panel-content',[_c('v-card',{staticClass:"mt-1 col-md-12",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"subtitle-2"},[_c('empty',{attrs:{"headline":"Coming soon"}})],1),_c('v-divider')],1)],1)],1)],1)]],2)],1)]),_c('ProductDialog',{attrs:{"dialog":_vm.open_product_dialog,"feature":_vm.activeFeature,"item":_vm.activeProduct},on:{"update:dialog":function($event){_vm.open_product_dialog=$event},"save":_vm.handleSaveProductPrice}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }