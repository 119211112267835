import {config_utils} from '@/services/configs/config_utils'
import request from '@/services/axios_instance' 
import ProductDialog from "@/modules/AdminArea/Products/ProductDialog.vue";

export default {
    name: 'Products',
    mixins: [config_utils ],
    components: {
        ProductDialog
    },
    data: () => ({
        activeFeature: null,
        activeProduct: null,
        paths: [
            {text: 'Admin', disabled: false, router_name: 'admin-dashboard'},
            {text: 'Products', disabled: false, router_name: 'admin-products'},
        ],
        panel_items: 10,
        all_plans: [],
        open_product_dialog: false
    }),
    created() {
        this.getAppPlans()
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
    },
    computed: {
        mains() {
            return this.all_plans.filter(i => i.feature_type === 'main')
        },
        modules() {
            return this.all_plans.filter(i => i.feature_type === 'module')
        },
        plugins() {
            return this.all_plans.filter(i => i.feature_type === 'plugin')
        }
    },
    methods: {
        openProductDialog(feature_type) {
            this.activeFeature = feature_type
            this.open_product_dialog = true
        },
        getAppPlans() {
            request.get(`api/upgrade/plans/apps`)
                .then(({data}) => {
                    this.all_plans = data
                })
        },
        updatePlanStatus(plan) {
            plan.hover = true;
            let new_status = plan.status === 'active' ? 'inactive' : 'active';
            request.put(`api/upgrade/plans/apps/${plan.id}/status`, {status: new_status})
                .then(({data}) => {
                    let index = this.all_plans.findIndex(i => i.id === plan.id)
                    if (~index) {
                        this.all_plans[index].status = new_status
                        this.appSnackbar( 'Subscription plan updated')
                    }
                })
                .finally(() => {
                    plan.hover = false;
                })
        },
        handleSaveProductPrice(payload) {
            request.post(`api/stripe/product-price`, payload)
                .then(({data}) => {
                    this.all_plans.push(data)
                    this.open_product_dialog = false
                    this.appSnackbar( 'New product added')
                })
        }
    }
}